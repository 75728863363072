import Vue from 'vue'
import App from './App.vue'
import router from './router'
import mock from './mock-data' // Import mock data and store into variable called mock

// Create a data object and pass it to new Vue bellow
let data = {
    products: mock,
    cart: []
}

Vue.config.productionTip = false

new Vue({
  router,
  data, // Pass data. This makes this.$root.$data avaliable to all components in the app
  render: h => h(App)
}).$mount('#app')
