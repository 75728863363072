<template>
<div>
    <table>
        <tr>
            <th></th>
            <th>Title</th>
            <th>Location</th>
            <th>Price</th>
        </tr>
        <tr v-for="item in this.$root.$data.cart" v-bind:key="item.id">
            <td>
                <img :src="'/images/products/'+item.image">
            </td>
            <td>{{item.name}}</td>
            <td>{{item.country}}</td>
            <td><b>{{item.price}}</b></td>
            <td class="left">
                <button @click="removeItem(item)" class="delete">Remove</button>
            </td>
        </tr>
    </table>
</div>
</template>

<script>
    export default {
        name: 'CartList',
        methods: {
            removeItem(item) {
                let cart = this.$root.$data.cart;

                for (var i = 0; i < cart.length; i++){
                    if (cart[i].id === item.id) {
                        cart.splice(i, 1); 
                        break;
                    }
                }
            }
        }
    }
</script>

<style>
    table {
        font-family: arial, sans-serif;
        border-collapse: collapse;
        width: 100%;
    }

    td, th {
        border: none;
        text-align: left;
        padding: 8px;
    }

    th {
        padding: 16px 0;
        font-size: large;
    }

    tr:nth-child(even) {
        background-color: #f2921d96;;
    }

    .delete {
        background-color: rgb(211, 2, 2);
        height: 30px;
    }

    .left {
        text-align: right;
    }

    td img  {
        width: 60px;
    }
</style>