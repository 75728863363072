<template>
    <div>
        <h1>Shopping Cart</h1>
        <hr>
        <div v-if="this.$root.$data.cart.length === 0">
            <h2>Your shopping cart is empty</h2>
        </div>
        <div v-else>
            <CartList />
        </div>

        <footer>
            <h3 style="text-align: center; margin-top: 100px; background-color: #F2921D; padding: 60px;">
                <text>Mother Earth © 2021</text> 
                <a href="https://github.com/BYU-CS-260-Winter-2021/lab-3b-grocery-store-logankimbs" target="blank">Github</a>
            </h3>
        </footer>
    </div>
</template>

<script>
    import CartList from '../components/CartList.vue'

    export default {
        name: 'Cart',
        components: {
            CartList
        }
    }
</script>