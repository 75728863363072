<template>
    <div>
        <div class="wrapper">
            <div class="search">
                <form class="pure-form">
                    <i class="fas fa-search"></i> <input v-model="searchText">
                </form>
            </div>
        </div>

        <ProductList :products="products" />

        <footer>
            <h3 style="text-align: center; margin-top: 100px; background-color: #F2921D; padding: 60px;">
                <text>Mother Earth © 2021</text> 
                <a href="https://github.com/BYU-CS-260-Winter-2021/lab-3b-grocery-store-logankimbs" target="blank">Github</a>
            </h3>
        </footer>
    </div>
</template>

<script>
    import ProductList from '../components/ProductList.vue'

    export default {
        name: 'Home',
        components: {
            ProductList
        },
        data() {
            return {
                searchText: '',
            }
        },
        computed: {
            products() {
                // Filter products
                return this.$root.$data.products.filter(product => product.name.toLowerCase().search(this.searchText.toLowerCase()) >= 0);
            }
        }
    }
</script>

<style scoped> /* scoped means it affects only this component */
    .wrapper {
        display: flex;
        align-items: center;
        justify-content: center;
    }

    .search {
        border: 1px solid #ccc;
        border-radius: 4px;
        width: 50%;
    }

    form {
        display: table;
        width: 100%;
    }

    i {
        display: table-cell;
        padding-left: 10px;
        width: 1px;
    }

    input {
        display: table-cell;
        font-size: 20px;
        border: none !important;
        box-shadow: none !important;
        width: 100%;
        height: 40px;
    }
</style>